import React from "react";

const ArrowRight = ({ color = "#5A32FB", width = 16, height = 17 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.7813 7.5194L7.20529 3.9434L8.14796 3.00073L13.3333 8.18607L8.14796 13.3714L7.20529 12.4287L10.7813 8.85273H2.66663V7.5194H10.7813Z"
            fill={color}
      />
    </svg>
  );
};

export default ArrowRight;
