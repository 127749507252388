import React from "react";

const Upwork = ({ width = 177, height = 80 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 177 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M48.856 32.7269C43.9427 32.7269 40.6901 29.005 39.7828 27.5633C40.947 18.443 44.3538 15.5594 48.856 15.5594C53.3071 15.5594 56.7653 19.0466 56.7653 24.1431C56.7653 29.2397 53.3071 32.7269 48.856 32.7269ZM48.856 9.95984C40.8443 9.95984 36.359 15.0732 35.075 20.3375C33.6199 17.655 32.5414 14.0673 31.7026 10.731H20.6263V24.227C20.6263 29.1224 18.3495 32.7437 13.8984 32.7437C9.44741 32.7437 6.89665 29.1224 6.89665 24.227L6.948 10.731H0.5625V24.227C0.5625 28.1668 1.86357 31.7378 4.24315 34.286C6.69122 36.9182 10.0295 38.2929 13.8984 38.2929C21.6021 38.2929 26.9777 32.509 26.9777 24.227V15.157C27.7821 18.1412 29.6996 23.8749 33.3631 28.9045L29.9393 48H36.4275L38.6871 34.4705C39.4233 35.074 40.2108 35.6105 41.0496 36.0968C43.2237 37.438 45.706 38.1924 48.274 38.2761C48.274 38.2761 48.6677 38.2929 48.8732 38.2929C56.8166 38.2929 63.1335 32.2744 63.1335 24.1431C63.1335 16.0121 56.7994 9.95984 48.856 9.95984Z"
          fill="#14A800"
      />
      <path
          d="M140.599 13.6154V10.7318H134.333V37.4386H140.684V26.9772C140.684 20.5394 141.284 15.9122 150.1 16.5158V10.4133C145.991 10.1115 142.79 10.9497 140.599 13.6154Z"
          fill="#14A800"
      />
      <path
          d="M96.5013 10.7329L91.8962 29.4093L86.9829 10.7329H78.9027L73.9723 29.4093L69.3843 10.7329H62.9474L70.0007 37.4567H77.6359L82.9428 18.4784L88.1984 37.4567H95.8336L103.298 10.7329H96.5013Z"
          fill="#14A800"
      />
      <path
          d="M117.284 32.7437C112.765 32.7437 109.375 29.2566 109.375 24.1431C109.375 19.0298 112.782 15.5594 117.284 15.5594C121.735 15.5594 125.193 19.0466 125.193 24.1431C125.193 29.2397 121.735 32.7437 117.284 32.7437ZM117.284 9.95984C109.289 9.95984 103.024 16.0288 103.024 24.1431C103.024 32.2574 109.289 38.2761 117.284 38.2761C125.227 38.2761 131.545 32.2574 131.545 24.1431C131.545 16.0288 125.245 9.95984 117.284 9.95984Z"
          fill="#14A800"
      />
      <path
          d="M159.208 24.3111H160.681L169.548 37.4549H176.722L166.518 22.886C171.466 20.9916 174.787 16.0459 174.787 10.7313H168.453C168.453 16.4985 163.813 18.678 159.208 18.678V0.186035H152.84V37.4549H159.191L159.208 24.3111Z"
          fill="#14A800"
      />
      <path
          d="M13.929 53L18.4725 61.2189L27.2955 63.2319L21.2805 70.3244L22.1899 79.7874L13.929 75.952L5.66804 79.7874L6.57741 70.3244L0.5625 63.2319L9.38546 61.2189L13.929 53Z"
          fill="#14A800"
      />
      <path
          d="M51.3551 53L55.8986 61.2189L64.7215 63.2319L58.7066 70.3244L59.616 79.7874L51.3551 75.952L43.0941 79.7874L44.0035 70.3244L37.9886 63.2319L46.8115 61.2189L51.3551 53Z"
          fill="#14A800"
      />
      <path
          d="M88.7813 53L93.3248 61.2189L102.148 63.2319L96.1328 70.3244L97.0422 79.7874L88.7813 75.952L80.5203 79.7874L81.4297 70.3244L75.4148 63.2319L84.2378 61.2189L88.7813 53Z"
          fill="#14A800"
      />
      <path
          d="M126.207 53L130.751 61.2189L139.574 63.2319L133.559 70.3244L134.468 79.7874L126.207 75.952L117.946 79.7874L118.856 70.3244L112.841 63.2319L121.664 61.2189L126.207 53Z"
          fill="#14A800"
      />
      <path
          d="M163.634 53L168.177 61.2189L177 63.2319L170.985 70.3244L171.895 79.7874L163.634 75.952L155.373 79.7874L156.282 70.3244L150.267 63.2319L159.09 61.2189L163.634 53Z"
          fill="#14A800"
      />
    </svg>
  );
};

export default Upwork;
