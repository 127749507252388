import React from "react";
import { HOST_IMAGE_URL } from "../../constants";
import Typography from "@mui/material/Typography";
import { Link } from "gatsby";
import SliderSmallArrow from "../../assets/icons/SliderSmallArrow";
import { ServicesItem } from "./style";

const Services = ({ categories, serviceItem }) => {
  return (
    <>
      {categories?.data?.map((category) => {
        return (
          <ServicesItem key={category.id} className={serviceItem}>
            <div>
              <Typography component="h3" className="servicesItemTitle">
                {category.attributes.preview.title}
                <img
                  className="servicesItemImage"
                  src={`${
                    HOST_IMAGE_URL +
                    category?.attributes?.preview?.icon?.data?.attributes?.url
                  }`}
                  alt={
                    category?.attributes?.preview?.icon?.data?.attributes
                      ?.alternativeText
                  }
                  title={
                    category?.attributes?.preview?.icon?.data?.attributes
                      ?.caption
                  }
                />
                <img
                  className="servicesItemHoverImage"
                  src={`${
                    HOST_IMAGE_URL +
                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                      ?.url
                  }`}
                  alt={
                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                      ?.alternativeText
                  }
                  title={
                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                      ?.caption
                  }
                />
              </Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: category?.attributes?.preview?.description,
                }}
              />
            </div>
            <Link
              className="servicesItemBottom"
              to={`/expertise/${category?.attributes?.slug}`}
            >
              Read More
              <div className="servicesArrowButton">
                <SliderSmallArrow />
              </div>
            </Link>
          </ServicesItem>
        );
      })}
    </>
  );
};

export default Services;
