import React from "react";
import Typography from "@mui/material/Typography";
import malevichAnimation from "../../assets/animations/malevichAnimation.json";
import LottieIcon from "../lottieIcons";
import { useBreakpointValue } from "../../hooks/use-breakpoint-value";
import {
    MainTargetRightTitle,
    SMainTargetRight,
    SMainTargetRightWrapper,
    SMainTargetWrapper,
    SMalevichTargetImg
} from "./style";

const MainTarget = ({ mainTargets }) => {
  const isMobile = useBreakpointValue(["smallMobile", "mobile", "bigMobile"]);

  const logoAnimation = {
    animationData: malevichAnimation,
    loop: 0,
    autoplay: true,
  };

  const createCountriesContent = (text) => {
    const spaceIndex = text.indexOf(" ");
    const modifiedText =
      text.slice(0, spaceIndex) + "\n" + text.slice(spaceIndex);
    return modifiedText;
  };

  return (
    <SMainTargetWrapper>
      <SMalevichTargetImg>
        <LottieIcon options={logoAnimation} replayInView disableOnHover />
      </SMalevichTargetImg>
      <SMainTargetRightWrapper>
        {isMobile ? (
          <SMainTargetRight>
            {mainTargets.data.map((target) => (
              <div key={target.id}>
                <MainTargetRightTitle>
                  {target.attributes.quantity}
                </MainTargetRightTitle>
                <Typography variant="body2">
                  {target.attributes.description}
                </Typography>
              </div>
            ))}
          </SMainTargetRight>
        ) : (
          <>
            <SMainTargetRight>
              {mainTargets?.data?.slice(0, 1).map((target) => (
                <div key={target.id}>
                  <MainTargetRightTitle>
                    {target.attributes.quantity}
                  </MainTargetRightTitle>
                  <Typography variant="body2" className="partnerCountries">
                    {createCountriesContent(target.attributes.description)}
                  </Typography>
                </div>
              ))}
            </SMainTargetRight>
            <SMainTargetRight
              sx={{
                maxWidth: "262px",
                marginLeft: "35px",
                "& > div": { maxWidth: "105px" },
              }}
            >
              {mainTargets?.data?.slice(1, 5).map((target) => (
                <div key={target.id}>
                  <MainTargetRightTitle>
                    {target.attributes.quantity}
                  </MainTargetRightTitle>
                  <Typography variant="body2">
                    {target.attributes.description}
                  </Typography>
                </div>
              ))}
            </SMainTargetRight>
          </>
        )}
      </SMainTargetRightWrapper>
    </SMainTargetWrapper>
  );
};

export default MainTarget;
