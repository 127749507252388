import React from "react";
import palette from "../../theme/palette";

const Address = ({ width = 16, height = 17 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1821_106)">
        <path
            d="M8 14.1195L11.3 10.8195C11.9526 10.1668 12.397 9.33529 12.577 8.43006C12.7571 7.52483 12.6646 6.58654 12.3114 5.73385C11.9582 4.88116 11.36 4.15235 10.5926 3.63959C9.82519 3.12683 8.92296 2.85315 8 2.85315C7.07704 2.85315 6.17481 3.12683 5.40739 3.63959C4.63997 4.15235 4.04183 4.88116 3.68861 5.73385C3.33539 6.58654 3.24294 7.52483 3.42297 8.43006C3.603 9.33529 4.04741 10.1668 4.7 10.8195L8 14.1195ZM8 16.0048L3.75734 11.7621C2.91823 10.923 2.34679 9.85393 2.11529 8.69005C1.88378 7.52616 2.0026 6.31977 2.45673 5.22341C2.91086 4.12706 3.6799 3.18999 4.66659 2.53071C5.65328 1.87142 6.81332 1.51953 8 1.51953C9.18669 1.51953 10.3467 1.87142 11.3334 2.53071C12.3201 3.18999 13.0891 4.12706 13.5433 5.22341C13.9974 6.31977 14.1162 7.52616 13.8847 8.69005C13.6532 9.85393 13.0818 10.923 12.2427 11.7621L8 16.0048ZM8 8.85281C8.35362 8.85281 8.69276 8.71234 8.94281 8.46229C9.19286 8.21224 9.33334 7.8731 9.33334 7.51948C9.33334 7.16586 9.19286 6.82672 8.94281 6.57667C8.69276 6.32662 8.35362 6.18615 8 6.18615C7.64638 6.18615 7.30724 6.32662 7.05719 6.57667C6.80715 6.82672 6.66667 7.16586 6.66667 7.51948C6.66667 7.8731 6.80715 8.21224 7.05719 8.46229C7.30724 8.71234 7.64638 8.85281 8 8.85281ZM8 10.1861C7.29276 10.1861 6.61448 9.90519 6.11438 9.4051C5.61429 8.905 5.33334 8.22672 5.33334 7.51948C5.33334 6.81223 5.61429 6.13396 6.11438 5.63386C6.61448 5.13376 7.29276 4.85281 8 4.85281C8.70725 4.85281 9.38552 5.13376 9.88562 5.63386C10.3857 6.13396 10.6667 6.81223 10.6667 7.51948C10.6667 8.22672 10.3857 8.905 9.88562 9.4051C9.38552 9.90519 8.70725 10.1861 8 10.1861Z"
            fill={palette.purpleBlue}
        />
      </g>
      <defs>
        <clipPath id="clip0_1821_106">
          <rect
              width="16"
              height="16"
              fill={palette.secondary}
              transform="translate(0 0.186035)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Address;
