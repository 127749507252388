import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import theme from "../../theme";

export const StyledArrowHeading = styled(Box)({
  display: "flex",
  alignItems: "center",

  "& svg": {
    display: "flex",
    marginRight: 44,
  },

  [theme.breakpoints.down("lg")]: {
    "& svg": {
      marginRight: 20,
    },
  },

  [theme.breakpoints.down("md")]: {
    "& svg": {
      display: "none",
    },
  },
});

export const VerticalArrow = styled(Box)({
  display: "flex",
  marginRight: 26,

  [theme.breakpoints.down("lg")]: {
    marginRight: 0,
  },
});
