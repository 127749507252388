import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../theme/palette";
import theme from "../../theme";

export const SMainTargetWrapper = styled(Box)({
    minHeight: 565,
    maxWidth: "579px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "@media (max-width: 899.99px)": {
        backgroundColor: palette.porcelain,
        maxWidth: "unset",
        minHeight: "unset",
        margin: "60px -26px 26px -26px",
    },
    "@media (max-width: 640.95px)": {
        margin: "60px -16px 26px -16px",
    },
});

export const SMainTargetRightWrapper = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginBottom: -26,
    marginTop: "-150px",
    "@media (max-width: 899.99px)": {
        alignItems: "unset",
        marginTop: "0px",
    },
});

export const SMainTargetRight = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 368,
    justifyContent: "space-between",

    "& .partnerCountries": {
        whiteSpace: "pre-line",
    },

    "@media (max-width: 1147.99px)": {
        paddingRight: 20,
    },

    "@media (max-width: 970px)": {
        paddingLeft: 20,
    },

    "@media (max-width: 640.45px)": {
        "& > div": {
            maxWidth: "112px",

            "&:first-of-type": {
                maxWidth: "215px",
            },
        },
    },

    [theme.breakpoints.down("lg")]: {
        width: "100%",
        maxWidth: "unset",
        padding: "36px 16px",
    },

    "& p": {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: "0.15px",
        marginBottom: 27,

        [theme.breakpoints.down("lg")]: {
            marginRight: 15,
        },
    },
});

export const MainTargetRightTitle = styled(Box)({
    margin: 0,
    padding: 0,
    fontSize: "48px",
    fontWeight: 600,
    lineHeight: "72px",
    letterSpacing: "-0.02em",

    [theme.breakpoints.down("lg")]: {
        fontSize: "34px",
        fontWeight: 500,
        lineHeight: "40px",
    },
});

export const SMalevichTargetImg = styled(Box)({
    width: "100%",
    display: "flex",
    height: 565,
    maxWidth: "579px",
    justifyContent: "center",

    "@media (max-width: 970px)": {
        height: "unset",
    },

    "& svg": {
        marginTop: "-45px",

        "@media (min-width: 971px) and (max-width: 1147.99px)": {
            width: "590px !important",
            height: "auto",
            margin: 0,
        },

        "@media (max-width: 899.99px)": {
            display: "none",
        },
    },
});

