import React from "react";
import SliderSmallArrow from "../../assets/icons/SliderSmallArrow";
import { HOST_IMAGE_URL } from "../../constants";
import { Slider } from "../slider";
import Typography from "@mui/material/Typography";
import { Link } from "gatsby";
import { ServicesItem } from "./style";

const ServicesSlider = ({ categories }) => {
  return (
    <Slider
      settings={{
        autoplay: false,
      }}
      isArrowBox
      width="100%"
      margin="0px"
      wrapperProps={{
        marginBottom: "68px",
      }}
    >
      {categories?.data?.map((category) => {
        return (
          <ServicesItem key={category.id}>
            <div>
              <Typography variant="h3" className="servicesItemTitle">
                {category.attributes.preview.title}
                <img
                  className="servicesItemImage"
                  src={`${
                    HOST_IMAGE_URL +
                    category?.attributes?.preview?.icon?.data?.attributes?.url
                  }`}
                  alt={
                    category?.attributes?.preview?.icon?.data?.attributes
                      ?.alternativeText
                  }
                  title={
                    category?.attributes?.preview?.icon?.data?.attributes
                      ?.caption
                  }
                />
                <img
                  className="servicesItemHoverImage"
                  src={`${
                    HOST_IMAGE_URL +
                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                      ?.url
                  }`}
                  alt={
                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                      ?.alternativeText
                  }
                  title={
                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                      ?.caption
                  }
                />
              </Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: category?.attributes?.preview?.description,
                }}
              />
            </div>
            <Link
              className="servicesItemBottom"
              to={`/expertise/${category?.attributes?.slug}`}
            >
              Read More
              <div className="servicesArrowButton">
                <SliderSmallArrow />
              </div>
            </Link>
          </ServicesItem>
        );
      })}
    </Slider>
  );
};

export default ServicesSlider;
