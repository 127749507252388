import React from "react";

const TechnologyImg = ({ width = 285, height = 189 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 285 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.587402 179.5V50.3936L205.998 0.536811L283.587 50.2739V179.5H0.587402Z" stroke="#151515"/>
      <path d="M76.7646 77.3063L62.0874 70.6816L62.7015 69.0005L77.4555 75.8017L76.7646 77.3063Z" stroke="#151515" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M77.0562 80.3536L77.6089 78.9795" stroke="#151515" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M77.9852 79.9164L76.6802 79.4404" stroke="#151515" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M82.6832 75.8091L80.4033 74.9033" stroke="#151515" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M81.0249 82.3112L82.4066 78.8799" stroke="#151515" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M83.2665 81.3057L80.0654 80.0391" stroke="#151515" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M39.5354 106.48V117.592C39.5354 118.366 39.7122 118.932 40.0659 119.292C40.4468 119.623 41.0726 119.789 41.9432 119.789H44.596V124.682H41.0046C36.1888 124.682 33.781 122.305 33.781 117.55V106.48H31.0874V101.712H33.781V96.0321H39.5354V101.712H44.596V106.48H39.5354Z" fill="#151515"/>
      <path d="M68.499 112.699C68.499 113.529 68.4446 114.275 68.3358 114.938H51.8072C51.9432 116.597 52.5146 117.896 53.5212 118.836C54.5279 119.775 55.7659 120.245 57.2351 120.245C59.3573 120.245 60.8673 119.319 61.7652 117.467H67.9277C67.2747 119.679 66.0231 121.503 64.173 122.94C62.3229 124.35 60.0511 125.055 57.3575 125.055C55.1809 125.055 53.222 124.571 51.4807 123.604C49.7666 122.609 48.4198 121.213 47.4403 119.416C46.4881 117.619 46.0119 115.546 46.0119 113.197C46.0119 110.82 46.4881 108.733 47.4403 106.936C48.3926 105.14 49.7258 103.758 51.4399 102.79C53.1539 101.823 55.1265 101.339 57.3575 101.339C59.5069 101.339 61.4251 101.809 63.1119 102.749C64.826 103.689 66.1456 105.029 67.0706 106.77C68.0229 108.484 68.499 110.461 68.499 112.699ZM62.5814 111.041C62.5542 109.548 62.0236 108.36 60.9897 107.475C59.9559 106.563 58.6907 106.107 57.1943 106.107C55.7795 106.107 54.5823 106.549 53.6029 107.434C52.6506 108.291 52.0656 109.493 51.848 111.041H62.5814Z" fill="#151515"/>
      <path d="M69.9759 113.197C69.9759 110.82 70.452 108.747 71.4043 106.978C72.3566 105.181 73.6761 103.799 75.363 102.832C77.0499 101.837 78.9816 101.339 81.1582 101.339C83.9606 101.339 86.2733 102.058 88.0962 103.495C89.9463 104.905 91.1842 106.895 91.81 109.465H85.6475C85.321 108.47 84.7632 107.696 83.9742 107.144C83.2124 106.563 82.2601 106.273 81.1174 106.273C79.485 106.273 78.1926 106.881 77.2403 108.097C76.2881 109.286 75.8119 110.986 75.8119 113.197C75.8119 115.381 76.2881 117.08 77.2403 118.297C78.1926 119.485 79.485 120.079 81.1174 120.079C83.4301 120.079 84.9401 119.029 85.6475 116.928H91.81C91.1842 119.416 89.9463 121.392 88.0962 122.857C86.2461 124.322 83.9334 125.055 81.1582 125.055C78.9816 125.055 77.0499 124.571 75.363 123.604C73.6761 122.609 72.3566 121.227 71.4043 119.458C70.452 117.661 69.9759 115.574 69.9759 113.197Z" fill="#151515"/>
      <path d="M107.505 101.381C109.219 101.381 110.742 101.768 112.075 102.541C113.409 103.288 114.443 104.407 115.177 105.9C115.939 107.365 116.32 109.134 116.32 111.207V124.682H110.606V111.995C110.606 110.17 110.157 108.774 109.259 107.807C108.362 106.812 107.137 106.314 105.586 106.314C104.008 106.314 102.757 106.812 101.832 107.807C100.934 108.774 100.485 110.17 100.485 111.995V124.682H94.7714V94.0005H100.485V104.573C101.22 103.578 102.199 102.804 103.423 102.251C104.648 101.671 106.008 101.381 107.505 101.381Z" fill="#151515"/>
      <path d="M133.058 101.381C135.751 101.381 137.928 102.251 139.588 103.993C141.247 105.706 142.077 108.111 142.077 111.207V124.682H136.364V111.995C136.364 110.17 135.915 108.774 135.017 107.807C134.119 106.812 132.895 106.314 131.344 106.314C129.766 106.314 128.514 106.812 127.589 107.807C126.691 108.774 126.242 110.17 126.242 111.995V124.682H120.529V101.712H126.242V104.573C127.004 103.578 127.97 102.804 129.14 102.251C130.337 101.671 131.643 101.381 133.058 101.381Z" fill="#151515"/>
      <path d="M156.326 125.055C154.149 125.055 152.19 124.571 150.449 123.604C148.708 122.609 147.334 121.213 146.327 119.416C145.348 117.619 144.858 115.546 144.858 113.197C144.858 110.847 145.361 108.774 146.368 106.978C147.402 105.181 148.803 103.799 150.571 102.832C152.34 101.837 154.312 101.339 156.489 101.339C158.666 101.339 160.638 101.837 162.407 102.832C164.175 103.799 165.563 105.181 166.57 106.978C167.603 108.774 168.12 110.847 168.12 113.197C168.12 115.546 167.59 117.619 166.529 119.416C165.495 121.213 164.08 122.609 162.284 123.604C160.516 124.571 158.53 125.055 156.326 125.055ZM156.326 119.997C157.36 119.997 158.326 119.748 159.223 119.25C160.149 118.725 160.883 117.951 161.427 116.928C161.971 115.906 162.244 114.662 162.244 113.197C162.244 111.013 161.672 109.341 160.529 108.18C159.414 106.992 158.04 106.397 156.407 106.397C154.775 106.397 153.401 106.992 152.286 108.18C151.197 109.341 150.653 111.013 150.653 113.197C150.653 115.381 151.184 117.067 152.245 118.255C153.333 119.416 154.693 119.997 156.326 119.997Z" fill="#151515"/>
      <path d="M176.801 94.0005V124.682H171.087V94.0005H176.801Z" fill="#151515"/>
      <path d="M191.261 125.055C189.084 125.055 187.126 124.571 185.384 123.604C183.643 122.609 182.269 121.213 181.262 119.416C180.283 117.619 179.793 115.546 179.793 113.197C179.793 110.847 180.296 108.774 181.303 106.978C182.337 105.181 183.738 103.799 185.507 102.832C187.275 101.837 189.248 101.339 191.424 101.339C193.601 101.339 195.573 101.837 197.342 102.832C199.11 103.799 200.498 105.181 201.505 106.978C202.539 108.774 203.056 110.847 203.056 113.197C203.056 115.546 202.525 117.619 201.464 119.416C200.43 121.213 199.015 122.609 197.22 123.604C195.451 124.571 193.465 125.055 191.261 125.055ZM191.261 119.997C192.295 119.997 193.261 119.748 194.159 119.25C195.084 118.725 195.818 117.951 196.363 116.928C196.907 115.906 197.179 114.662 197.179 113.197C197.179 111.013 196.607 109.341 195.465 108.18C194.349 106.992 192.975 106.397 191.343 106.397C189.71 106.397 188.336 106.992 187.221 108.18C186.132 109.341 185.588 111.013 185.588 113.197C185.588 115.381 186.119 117.067 187.18 118.255C188.268 119.416 189.629 119.997 191.261 119.997Z" fill="#151515"/>
      <path d="M214.878 101.339C216.565 101.339 218.048 101.685 219.327 102.376C220.606 103.039 221.612 103.91 222.347 104.988V101.712H228.101V124.847C228.101 126.976 227.68 128.869 226.836 130.528C225.993 132.214 224.728 133.54 223.041 134.508C221.354 135.503 219.313 136 216.919 136C213.709 136 211.069 135.24 209.002 133.72C206.961 132.2 205.805 130.127 205.533 127.501H211.205C211.505 128.551 212.144 129.381 213.124 129.989C214.13 130.624 215.341 130.942 216.756 130.942C218.415 130.942 219.762 130.431 220.796 129.408C221.83 128.413 222.347 126.893 222.347 124.847V121.282C221.612 122.36 220.592 123.258 219.286 123.977C218.007 124.695 216.538 125.055 214.878 125.055C212.974 125.055 211.233 124.557 209.655 123.562C208.077 122.567 206.825 121.171 205.9 119.375C205.002 117.55 204.553 115.463 204.553 113.114C204.553 110.792 205.002 108.733 205.9 106.936C206.825 105.14 208.063 103.758 209.614 102.79C211.192 101.823 212.947 101.339 214.878 101.339ZM222.347 113.197C222.347 111.787 222.075 110.585 221.531 109.59C220.987 108.567 220.252 107.793 219.327 107.268C218.402 106.715 217.409 106.439 216.348 106.439C215.287 106.439 214.307 106.701 213.409 107.227C212.511 107.752 211.777 108.526 211.205 109.548C210.661 110.543 210.389 111.732 210.389 113.114C210.389 114.496 210.661 115.712 211.205 116.763C211.777 117.785 212.511 118.573 213.409 119.126C214.334 119.679 215.314 119.955 216.348 119.955C217.409 119.955 218.402 119.692 219.327 119.167C220.252 118.615 220.987 117.841 221.531 116.846C222.075 115.823 222.347 114.607 222.347 113.197Z" fill="#151515"/>
      <path d="M254.087 101.712L240.089 135.544H234.008L238.906 124.101L229.845 101.712H236.253L242.089 117.758L248.007 101.712H254.087Z" fill="#151515"/>
      <path d="M35.1304 105.039C25.9784 102.157 11.4063 121.864 71.5874 150.238C106.311 166.604 135.512 160.551 133.858 150.238C130.906 131.73 113.213 134.561 121.979 138.438C134.728 144.074 159.986 135.269 174.249 118.423C188.322 101.795 171.327 93.1837 169.87 92.8887" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M84.2005 134.7L83.0874 127L89.6507 131.361L86.2194 132.113L84.2005 134.7Z" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M206.125 45.0945L186.582 60.4878L188.162 35.3397L195.732 43.6396L206.125 45.0945Z" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default TechnologyImg;
