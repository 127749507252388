import React from "react";

const HomeTitleSecondImg = ({ width = 260, height = 183 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 260 183"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M228.062 178.219C239.001 190.702 262.894 166.171 199.409 70.1217C162.774 14.7106 129.507 5.88506 122.705 22.6483C110.476 52.7139 139.156 64.401 129.678 50.7897C115.895 30.9974 70.6811 21.3691 34.5046 33.5228C-1.19519 45.5211 16.1967 73.5331 18.085 75.2777" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M227.301 174.452C226.199 174.31 225.191 175.088 225.05 176.19C224.908 177.292 225.686 178.3 226.787 178.441C227.889 178.583 228.897 177.805 229.039 176.703C229.181 175.602 228.403 174.594 227.301 174.452Z" fill="#151515"/>
      <path d="M17.7867 73.103C16.3402 72.9168 15.0167 73.9384 14.8305 75.3849C14.6443 76.8313 15.6659 78.1549 17.1124 78.3411C18.5589 78.5273 19.8824 77.5056 20.0686 76.0592C20.2548 74.6127 19.2331 73.2892 17.7867 73.103Z" fill="#151515"/>
    </svg>
  );
};

export default HomeTitleSecondImg;
