import palette from "../theme/palette";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import theme from "../theme";
import { Link } from "gatsby";

// TOP SECTION
export const SSpecializationContainer = styled(Box)({
  marginTop: 78,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    "& h1": {
      marginBottom: 23,
    },
  },
});

export const STitleBlock = styled(Box)({
  position: "relative",
  maxWidth: 660,
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    "& h1": {
      fontSize: "48px",
      lineHeight: "56px",

      "& strong": {
        fontSize: "48px",
        lineHeight: "56px",
      },
    },
  },

  "@media (max-width: 640px)": {
    maxWidth: 343,
  },
});

export const TitleFirstIcon = styled(Box)({
  position: "absolute",
  top: "-30px",
  left: 0,
  width: "428px",
  height: "37px",

  [theme.breakpoints.down("lg")]: {
    top: "-34px",
    width: "343px",

    "& svg": {
      width: "100%",
    },
  },

  "@media (max-width: 375px)": {
    display: "none",
  },
});

export const TitleSecondIcon = styled(Box)({
  position: "absolute",
  top: "-70px",
  right: "50px",
  width: "260px",
  height: "183px",

  [theme.breakpoints.down("lg")]: {
    top: "-85px",
    right: "-40px",
  },

  [theme.breakpoints.down("md")]: {
    top: "-75px",
    right: "-20px",
  },

  "@media (max-width: 375px)": {
    display: "none",
  },
});

export const TitleThirdIcon = styled(Box)({
  position: "absolute",
  top: "46%",
  left: "81%",

  [theme.breakpoints.down("lg")]: {
    top: "31%",
    left: "90%",
  },

  [theme.breakpoints.down("md")]: {
    top: "56%",
    left: "81%",
  },

  "@media (max-width: 375px)": {
    display: "none",
  },
});

export const SImgContainer = styled(Box)({
  flexShrink: 0,
  marginLeft: 15,

  "@media (max-width: 1148px)": {
    display: "none",
    marginLeft: 0,
  },
});

export const SHomeTopAnimation = styled(Box)({
  width: 364,
  height: 467,
});

export const STechnologiesPreview = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const SPreviewImg = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: 36,

  "& div img": {
    marginBottom: 0,
  },

  [theme.breakpoints.down("lg")]: {
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginTop: 10,

    "& div": {
      margin: "26px 13px 0",
    },
  },
});
// END OF TOP SECTION

// SERVICES SECTION
export const ServicesContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  "& .desktopServiceItem": {
    "@media(max-width: 640px)": {
      display: "none !important",
    },
  },
});
// END OF SERVICES SECTION

// ADVANTAGES SECTION
export const SAdvantagesContainer = styled(Box)({
  marginTop: 120,

  // [theme.breakpoints.down("lg")]: {
  //   marginTop: 60,
  // },
  "@media(max-width: 901px)": {
    marginTop: 60,
  },

  "@media (max-width: 1188px)": {
    overflowX: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    marginRight: -20,

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& .MuiTypography-root": {
      fontSize: 34,
      lineHeight: "40px",
    },
  },
});

export const SAdvantagesContent = styled(Box)({
  display: "flex",
  flexWrap: "wrap",

  "@media (max-width: 1188px)": {
    flexWrap: "unset",
    width: 1240,
  },

  "& h3": {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: "0.15px",
    marginBottom: 17,
  },

  "& p": {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0.15px",
    marginBottom: 17,
  },
});

export const SAdvantagesItem = styled(Box)({
  maxWidth: 405,
  width: "100%",
  marginTop: 32,
  marginRight: 79,

  "@media (max-width: 1188px)": {
    marginRight: 0,
    marginTop: 26,
    maxWidth: 310,
  },

  "&:last-child": {
    marginRight: 0,
  },

  "&:nth-of-type(3)": {
    marginLeft: 259,

    "@media (max-width: 1188px)": {
      marginLeft: 0,
    },
  },

  "&:nth-last-of-child(-n+2)": {
    marginTop: 42,

    "@media (max-width: 1188px)": {
      marginTop: 26,
    },
  },
});

export const SLinkContainer = styled(Link)({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  color: palette.purpleBlue,
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
});

export const SPlusContainer = styled(Box)({
  width: 36,
  height: 36,
  border: `1px solid ${palette.purpleBlue}`,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: palette.purpleBlue,
  backgroundColor: palette.secondary,
  marginLeft: 16,
});
// END OF ADVANTAGES SECTION

// UPWORK SECTION
export const SUpworkContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: 120,

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 60,
  },
});

export const SUpworkTitle = styled(Typography)({
  maxWidth: 450,
  width: "100%",
  lineHeight: "52px",
  textTransform: "uppercase",

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
    marginBottom: 26,
  },
});

export const SUpworkArrow = styled(Box)({
  display: "flex",

  "@media (max-width: 1148px)": {
    display: "none",
  },
});

export const SUpworkWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const SUpworkItem = styled(Box)({
  display: "flex",
  flexDirection: "column",

  a: {
    "&:nth-of-type(2)": {
      marginTop: 16,
    },
  },

  "&:last-child": {
    marginLeft: 90,
  },

  "@media (max-width: 1148px)": {
    a: {
      width: "130px",

      "& svg": {
        width: "100%",
        height: "auto",
      },
    },

    "&:last-child": {
      marginLeft: 40,

      a: {
        width: "174px",
      },
    },
  },

  "@media (max-width: 385px)": {
    "&:last-child": {
      marginLeft: 20,

      a: {
        width: "130px",
      },
    },
  },
});

// END OF UPWORK SECTION

// CASES SECTION
export const SCasesContainer = styled(Box)({
  marginTop: 120,

  [theme.breakpoints.down("lg")]: {
    marginTop: 60,
  },

  "& a": {
    textDecoration: "unset",
  },
});

export const CasesSubTitle = styled(Typography)({
  maxWidth: 460,
  marginTop: 32,
  marginBottom: 49,

  [theme.breakpoints.down("md")]: {
    margin: "10px 0",
  },
});

export const SLinkCases = styled(Link)({
  maxWidth: 264,
  width: "100%",

  "@media (max-width: 450px)": {
    maxWidth: "unset",
  },
});

export const SBtnContainer = styled(Button)({
  "&.MuiButtonBase-root.MuiButton-root": {
    justifyContent: "space-between",
    maxWidth: 264,
    width: "100%",
    height: 42,
    padding: "0 26px",
    color: palette.secondary,
    fontSize: 16,
    lineHeight: "24px",
    backgroundColor: palette.purpleBlue,
    border: `1px solid ${palette.purpleBlue}`,
    borderRadius: 0,

    "&:hover": {
      backgroundColor: palette.secondary,
      color: palette.purpleBlue,

      "& svg path": {
        fill: palette.purpleBlue,
      },
    },

    [theme.breakpoints.down("sm")]: {
      height: 56,
      padding: "0 16px",
    },

    [theme.breakpoints.down("xl")]: {
      marginTop: "16px",
    },

    "&:hover a": {
      color: palette.purpleBlue,
    },

    "@media (max-width: 575px)": {
      width: "100%",
    },

    "@media (max-width: 450px)": {
      maxWidth: "unset",
    },
  },

  "& a": {
    color: palette.secondary,
    textDecoration: "none",
  },

  "&.btnOutline": {
    "&.MuiButtonBase-root.MuiButton-root": {
      maxWidth: 380,
      width: "100%",
      backgroundColor: palette.secondary,
      color: palette.purpleBlue,
      marginBottom: 26,

      "&:hover": {
        backgroundColor: palette.purpleBlue,
        color: palette.secondary,

        "& svg path": {
          fill: palette.secondary,
        },
      },

      "&:hover a": {
        color: palette.secondary,
      },

      "@media (max-width: 640px)": {
        marginTop: 0,
        marginBottom: 26,
      },
      "@media (max-width: 450px)": {
        maxWidth: "unset",
      },
    },

    "& a": {
      color: palette.purpleBlue,
    },
  },
});

export const SBtnWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: -68,

  [theme.breakpoints.down("xl")]: {
    justifyContent: "flex-start",
    marginTop: 0,
  },
});

export const SCasesContent = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 16px",
  },
});
// END OF CASES SECTION

// CUSTOMER REVIEWS SECTION
export const SReviewsContainer = styled(Box)({
  position: "relative",
  maxWidth: 880,
  width: "100%",
  marginTop: 170,

  "& h2": {
    lineHeight: "58px",
  },

  "& .carousel.carousel-slider": {
    position: "static",
  },

  [theme.breakpoints.down("lg")]: {
    /*position: "static",*/
    margin: "60px 0 72px",
    paddingTop: 36,

    "& h2": {
      marginBottom: 34,
      lineHeight: "38px",
    },
  },
});

export const ReviewsContainerBackgroundInner = styled(Box)({
  position: "absolute",
  top: 0,
  right: 0,

  "& svg": {
    width: "100%",
    height: "100%",
  },

  [theme.breakpoints.down("lg")]: {
    top: "120px",
    width: "224px",
    height: "139px",
  },

  [theme.breakpoints.down("sm")]: {
    top: "36px",
    width: "272px",
    height: "172px",
  },
});

export const SReviewsTitle = styled(Typography)({
  width: 667,

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
});

export const SCustomerContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  marginTop: "43.6px",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    marginTop: 0,
  },
});

export const SCustomerInfo = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    position: "relative",
  },
});

export const SCustomerLeft = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const SCustomerTitle = styled(Box)({
  display: "flex",
  alignItems: "flex-end",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

export const SCustomerName = styled(Typography)({
  color: palette.primary,
  fontWeigh: "500",
});

export const SCustomerCompany = styled(Typography)({
  marginLeft: "8px",
  color: palette.carbonGrey,

  [theme.breakpoints.down("lg")]: {
    marginLeft: 0,
  },
});

export const SCustomerAddress = styled(Typography)({
  display: "flex",
  alignItems: "center",
  color: palette.purpleBlue,

  "& svg": {
    marginRight: 2,
  },
});

export const SRating = styled(Rating)({
  [theme.breakpoints.down("sm")]: {
    position: "absolute",
    top: -4,
    right: 0,
  },
});

export const SCustomerText = styled(Typography)({
  marginTop: 16,
});

export const SRatingIcon = styled(Box)({
  width: 24,
  height: 24,
  marginLeft: 10,

  "& svg": {
    width: "100%",
    height: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    width: 12,
    height: 12,
    marginLeft: 6,
  },
});

export const SArrowPrev = styled(Box)({
  display: "flex",
  position: "absolute",
  top: 18,
  right: 46,
  height: 20,
  cursor: "pointer",

  "&:hover svg path": {
    fill: palette.primary,
  },

  "& svg": {
    transform: "rotate(180deg)",
  },

  "& svg path": {
    fill: palette.amethystSmoke,

    "&:hover": {
      fill: palette.primary,
    },
  },

  [theme.breakpoints.down("lg")]: {
    top: "unset",
    bottom: -36,
    left: "calc(50% - 40px)",
  },
});

export const SArrowNext = styled(Box)({
  display: "flex",
  position: "absolute",
  top: 18,
  right: 0,
  height: 20,
  cursor: "pointer",

  "&:hover svg path": {
    fill: palette.primary,
  },

  "& svg path": {
    fill: palette.amethystSmoke,

    "&:hover": {
      fill: palette.primary,
    },
  },

  [theme.breakpoints.down("lg")]: {
    top: "unset",
    bottom: -36,
    right: "calc(50% - 40px)",
  },
});
// END OF CUSTOMER REVIEWS SECTION

export const SServicesWrapper = styled(Box)({
  marginTop: 120,

  [theme.breakpoints.down("lg")]: {
    marginTop: 60,
  },
});
