import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../theme/palette";
export const ServicesItem = styled(Box)({
    display: "flex !important",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "362px",
    width: "100%",
    marginTop: "44px",
    padding: 26,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    border: `1px solid ${palette.amethystSmoke}`,
    backgroundColor: palette.secondary,

    "& .servicesItemTitle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "26px",
        paddingBottom: "26px",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        textTransform: "uppercase",
        borderBottom: `1px solid ${palette.primary}`,
    },

    "& .servicesItemImage": {
        marginBottom: 0,
    },

    "& .servicesItemHoverImage": {
        display: "none",
    },

    "& .servicesItemBottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 26,
        color: palette.purpleBlue,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
    },

    "& .servicesArrowButton": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 36,
        height: 36,
        marginLeft: 16,
        color: palette.purpleBlue,
        border: `1px solid ${palette.purpleBlue}`,
        borderRadius: "50%",

        "& svg path": {
            fill: palette.purpleBlue,
        },
    },

    "&:hover": {
        color: palette.secondary,
        background: palette.primary,

        "& .servicesItemTitle": {
            borderBottom: `1px solid ${palette.secondary}`,
        },

        "& .servicesItemImage": {
            display: "none",
        },

        "& .servicesItemHoverImage": {
            display: "flex",
            marginBottom: 0,
        },

        "& .servicesItemBottom": {
            color: palette.secondary,
        },

        "& .servicesArrowButton": {
            border: `1px solid ${palette.secondary}`,

            "& svg": {
                transform: "rotate(90deg)",

                "& path": {
                    fill: palette.secondary,
                },
            },
        },
    },

    // [theme.breakpoints.down("md")]: {
    //   maxWidth: "unset",
    //   height: "100%",
    //   marginTop: 0,
    //   color: palette.secondary,
    //   background: palette.primary,

    //   "& .servicesItemTitle": {
    //     borderBottom: `1px solid ${palette.secondary}`,
    //   },

    //   "& .servicesItemImage": {
    //     display: "none",
    //   },

    //   "& .servicesItemHoverImage": {
    //     display: "flex",
    //     marginBottom: 0,
    //   },

    //   "& .servicesItemBottom": {
    //     color: palette.secondary,
    //   },

    //   "& .servicesArrowButton": {
    //     border: `1px solid ${palette.secondary}`,

    //     "& svg path": {
    //       fill: palette.secondary,
    //     },
    //   },

    //   "&:hover": {
    //     "& .servicesArrowButton svg": {
    //       transform: "rotate(0deg)",
    //     },
    //   },
    // },

    // [theme.breakpoints.down("lg")]: {
    //   maxWidth: "unset",
    // },

    "@media(max-width: 641px)": {
        maxWidth: "unset",
        height: "100%",
        marginTop: 0,
        color: palette.secondary,
        background: palette.primary,

        "& .servicesItemTitle": {
            borderBottom: `1px solid ${palette.secondary}`,
        },

        "& .servicesItemImage": {
            display: "none",
        },

        "& .servicesItemHoverImage": {
            display: "flex",
            marginBottom: 0,
        },

        "& .servicesItemBottom": {
            color: palette.secondary,
        },

        "& .servicesArrowButton": {
            border: `1px solid ${palette.secondary}`,

            "& svg path": {
                fill: palette.secondary,
            },
        },

        "&:hover": {
            "& .servicesArrowButton svg": {
                transform: "rotate(0deg)",
            },
        },
    },

    "@media(max-width: 901px)": {
        maxWidth: "unset",
    },

    "@media (max-width: 375px)": {
        padding: 16,
        fontSize: "14px",
        lineHeight: "20px",

        "& .servicesItemTitle": {
            marginBottom: "16px",
            paddingBottom: "16px",
        },
    },
});
