import React from "react";

const UpworkPro = ({ width = 185, height = 59 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 185 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M35.5554 24.366C31.9953 24.366 29.6385 21.6691 28.981 20.6245C29.8246 14.0161 32.2931 11.9266 35.5554 11.9266C38.7806 11.9266 41.2863 14.4534 41.2863 18.1462C41.2863 21.8392 38.7806 24.366 35.5554 24.366ZM35.5554 7.86926C29.7502 7.86926 26.5002 11.5744 25.5698 15.3888C24.5155 13.4451 23.734 10.8455 23.1262 8.42806H15.1005V18.2071C15.1005 21.7542 13.4507 24.3781 10.2255 24.3781C7.00039 24.3781 5.15214 21.7542 5.15214 18.2071L5.18935 8.42806H0.5625V18.2071C0.5625 21.0618 1.50524 23.6493 3.22945 25.4957C5.00329 27.4029 7.42215 28.399 10.2255 28.399C15.8075 28.399 19.7026 24.2081 19.7026 18.2071V11.6351C20.2855 13.7974 21.6749 17.9519 24.3294 21.5963L21.8485 35.4327H26.5499L28.1871 25.6293C28.7205 26.0666 29.2911 26.4554 29.8989 26.8077C31.4743 27.7796 33.2729 28.3262 35.1337 28.3869C35.1337 28.3869 35.4189 28.399 35.5678 28.399C41.3235 28.399 45.9006 24.0381 45.9006 18.1462C45.9006 12.2546 41.311 7.86926 35.5554 7.86926Z"
          fill="#14A800"
      />
      <path
          d="M102.031 10.5181V8.42865H97.491V27.78H102.093V20.1999C102.093 15.5351 102.527 12.1823 108.915 12.6196V8.19784C105.938 7.97918 103.619 8.58656 102.031 10.5181Z"
          fill="#14A800"
      />
      <path
          d="M70.0785 8.42944L66.7417 21.9621L63.1816 8.42944H57.3268L53.7543 21.9621L50.4299 8.42944H45.7658L50.8765 27.7931H56.4089L60.2542 14.0417L64.0624 27.7931H69.5948L75.0031 8.42944H70.0785Z"
          fill="#14A800"
      />
      <path
          d="M85.1374 24.3781C81.8627 24.3781 79.4067 21.8515 79.4067 18.1462C79.4067 14.4412 81.8752 11.9266 85.1374 11.9266C88.3627 11.9266 90.8684 14.4534 90.8684 18.1462C90.8684 21.8392 88.3627 24.3781 85.1374 24.3781ZM85.1374 7.86926C79.3446 7.86926 74.8046 12.2668 74.8046 18.1462C74.8046 24.0258 79.3446 28.3869 85.1374 28.3869C90.8931 28.3869 95.4704 24.0258 95.4704 18.1462C95.4704 12.2668 90.9056 7.86926 85.1374 7.86926Z"
          fill="#14A800"
      />
      <path
          d="M115.515 18.2681H116.582L123.008 27.7919H128.205L120.812 17.2355C124.397 15.8628 126.803 12.2792 126.803 8.42832H122.214C122.214 12.6072 118.852 14.1864 115.515 14.1864V0.787354H110.901V27.7919H115.503L115.515 18.2681Z"
          fill="#14A800"
      />
      <path
          d="M146.94 14.6157C146.94 15.6078 146.697 16.5378 146.211 17.4059C145.744 18.274 144.997 18.9737 143.972 19.5052C142.964 20.0367 141.687 20.3024 140.14 20.3024H136.983V27.4241H133.205V8.87584H140.14C141.597 8.87584 142.838 9.12386 143.864 9.6199C144.889 10.1159 145.654 10.798 146.157 11.666C146.679 12.5341 146.94 13.5173 146.94 14.6157ZM139.978 17.2996C141.021 17.2996 141.795 17.0693 142.299 16.6087C142.802 16.1304 143.054 15.4661 143.054 14.6157C143.054 12.8087 142.029 11.9052 139.978 11.9052H136.983V17.2996H139.978Z"
          fill="#9A9AAF"
      />
      <path
          d="M158.796 27.4241L154.641 20.1961H152.86V27.4241H149.082V8.87584H156.152C157.609 8.87584 158.85 9.13272 159.876 9.64647C160.901 10.1425 161.666 10.8246 162.169 11.6926C162.691 12.543 162.952 13.4996 162.952 14.5626C162.952 15.7849 162.592 16.8922 161.873 17.8842C161.153 18.8586 160.083 19.5318 158.662 19.9038L163.168 27.4241H158.796ZM152.86 17.4059H156.017C157.043 17.4059 157.807 17.1668 158.311 16.6884C158.814 16.1924 159.066 15.5103 159.066 14.6423C159.066 13.7919 158.814 13.1364 158.311 12.6758C157.807 12.1975 157.043 11.9584 156.017 11.9584H152.86V17.4059Z"
          fill="#9A9AAF"
      />
      <path
          d="M174.572 27.6101C172.809 27.6101 171.19 27.2026 169.715 26.3877C168.24 25.5728 167.07 24.4479 166.207 23.0129C165.343 21.5602 164.912 19.9215 164.912 18.0968C164.912 16.2898 165.343 14.6688 166.207 13.2339C167.07 11.7812 168.24 10.6474 169.715 9.83249C171.19 9.01757 172.809 8.61011 174.572 8.61011C176.353 8.61011 177.972 9.01757 179.429 9.83249C180.904 10.6474 182.064 11.7812 182.91 13.2339C183.773 14.6688 184.205 16.2898 184.205 18.0968C184.205 19.9215 183.773 21.5602 182.91 23.0129C182.064 24.4479 180.904 25.5728 179.429 26.3877C177.954 27.2026 176.335 27.6101 174.572 27.6101ZM174.572 24.2884C175.705 24.2884 176.704 24.0404 177.567 23.5444C178.431 23.0306 179.105 22.3043 179.591 21.3654C180.077 20.4264 180.319 19.3369 180.319 18.0968C180.319 16.8567 180.077 15.7761 179.591 14.8549C179.105 13.9159 178.431 13.1985 177.567 12.7024C176.704 12.2064 175.705 11.9584 174.572 11.9584C173.439 11.9584 172.431 12.2064 171.55 12.7024C170.686 13.1985 170.012 13.9159 169.526 14.8549C169.04 15.7761 168.797 16.8567 168.797 18.0968C168.797 19.3369 169.04 20.4264 169.526 21.3654C170.012 22.3043 170.686 23.0306 171.55 23.5444C172.431 24.0404 173.439 24.2884 174.572 24.2884Z"
          fill="#9A9AAF"
      />
      <path
          d="M41.0283 41.6918V44.0238H37.2616V56.0962H34.4264V44.0238H30.6596V41.6918H41.0283Z"
          fill="#5A32FB"
      />
      <path
          d="M49.2534 56.2407C47.9303 56.2407 46.7153 55.9242 45.6082 55.2914C44.5011 54.6585 43.6235 53.7849 42.9755 52.6705C42.3274 51.5424 42.0034 50.2698 42.0034 48.8528C42.0034 47.4495 42.3274 46.1906 42.9755 45.0762C43.6235 43.9481 44.5011 43.0676 45.6082 42.4348C46.7153 41.8019 47.9303 41.4855 49.2534 41.4855C50.59 41.4855 51.8051 41.8019 52.8987 42.4348C54.0058 43.0676 54.8766 43.9481 55.5111 45.0762C56.1592 46.1906 56.4832 47.4495 56.4832 48.8528C56.4832 50.2698 56.1592 51.5424 55.5111 52.6705C54.8766 53.7849 54.0058 54.6585 52.8987 55.2914C51.7916 55.9242 50.5765 56.2407 49.2534 56.2407ZM49.2534 53.6611C50.104 53.6611 50.8533 53.4685 51.5013 53.0833C52.1494 52.6843 52.6557 52.1202 53.0202 51.3911C53.3847 50.6619 53.567 49.8158 53.567 48.8528C53.567 47.8897 53.3847 47.0505 53.0202 46.3351C52.6557 45.6059 52.1494 45.0487 51.5013 44.6635C50.8533 44.2783 50.104 44.0857 49.2534 44.0857C48.4029 44.0857 47.6468 44.2783 46.9853 44.6635C46.3372 45.0487 45.8309 45.6059 45.4664 46.3351C45.1019 47.0505 44.9196 47.8897 44.9196 48.8528C44.9196 49.8158 45.1019 50.6619 45.4664 51.3911C45.8309 52.1202 46.3372 52.6843 46.9853 53.0833C47.6468 53.4685 48.4029 53.6611 49.2534 53.6611Z" fill="#5A32FB"/>
      <path
          d="M68.4955 46.1494C68.4955 46.9198 68.3132 47.6421 67.9487 48.3162C67.5976 48.9903 67.0374 49.5338 66.2678 49.9465C65.5117 50.3592 64.5532 50.5656 63.3921 50.5656H61.0227V56.0962H58.1875V41.6918H63.3921C64.4857 41.6918 65.4172 41.8844 66.1868 42.2697C66.9563 42.6549 67.5301 43.1846 67.9082 43.8587C68.2997 44.5328 68.4955 45.2964 68.4955 46.1494ZM63.2706 48.2337C64.0536 48.2337 64.6342 48.0548 65.0122 47.6971C65.3902 47.3256 65.5793 46.8097 65.5793 46.1494C65.5793 44.7461 64.8097 44.0444 63.2706 44.0444H61.0227V48.2337H63.2706Z"
          fill="#5A32FB"
      />
      <path
          d="M81.8144 56.0962L78.6957 50.4831H77.3591V56.0962H74.5239V41.6918H79.8298C80.9234 41.6918 81.8549 41.8913 82.6245 42.2903C83.394 42.6755 83.9678 43.2052 84.3458 43.8793C84.7374 44.5397 84.9331 45.2826 84.9331 46.1081C84.9331 47.0574 84.6631 47.9172 84.1231 48.6877C83.583 49.4443 82.7797 49.9671 81.7132 50.2561L85.0951 56.0962H81.8144ZM77.3591 48.3162H79.7285C80.4981 48.3162 81.0719 48.1305 81.4499 47.759C81.8279 47.3738 82.0169 46.8441 82.0169 46.17C82.0169 45.5096 81.8279 45.0006 81.4499 44.6429C81.0719 44.2714 80.4981 44.0857 79.7285 44.0857H77.3591V48.3162Z"
          fill="#5A32FB"
      />
      <path
          d="M95.7601 53.3515H90.1302L89.1986 56.0962H86.2216L91.3047 41.6712H94.6057L99.6888 56.0962H96.6916L95.7601 53.3515ZM94.9905 51.0402L92.9451 45.0143L90.8997 51.0402H94.9905Z"
          fill="#5A32FB"
      />
      <path
          d="M110.803 41.6918V44.0238H107.036V56.0962H104.201V44.0238H100.435V41.6918H110.803Z"
          fill="#5A32FB"
      />
      <path
          d="M115.302 44.0238V47.6352H120.061V49.9259H115.302V53.7436H120.669V56.0962H112.467V41.6712H120.669V44.0238H115.302Z"
          fill="#5A32FB"
      />
      <path
          d="M127.782 41.6918C129.267 41.6918 130.569 41.9876 131.69 42.5792C132.824 43.1708 133.695 44.0169 134.302 45.1175C134.924 46.2044 135.234 47.4701 135.234 48.9147C135.234 50.3592 134.924 51.6249 134.302 52.7118C133.695 53.7849 132.824 54.6173 131.69 55.2088C130.569 55.8004 129.267 56.0962 127.782 56.0962H122.84V41.6918H127.782ZM127.68 53.6405C129.165 53.6405 130.313 53.2277 131.123 52.4023C131.933 51.5768 132.338 50.4143 132.338 48.9147C132.338 47.4151 131.933 46.2457 131.123 45.4064C130.313 44.5535 129.165 44.127 127.68 44.127H125.675V53.6405H127.68Z"
          fill="#5A32FB"
      />
      <path
          d="M148.611 46.1047C148.611 46.8597 148.428 47.5675 148.063 48.2281C147.711 48.8887 147.149 49.4212 146.378 49.8257C145.62 50.2301 144.66 50.4323 143.496 50.4323H141.121V55.852H138.28V41.7366H143.496C144.592 41.7366 145.526 41.9254 146.297 42.3029C147.068 42.6804 147.643 43.1994 148.022 43.86C148.415 44.5206 148.611 45.2688 148.611 46.1047ZM143.374 48.1472C144.159 48.1472 144.741 47.9719 145.12 47.6214C145.499 47.2574 145.688 46.7518 145.688 46.1047C145.688 44.7296 144.917 44.042 143.374 44.042H141.121V48.1472H143.374Z"
          fill="#9A9AAF"
      />
      <path
          d="M153.47 53.6073H158.138V55.852H150.628V41.7366H153.47V53.6073Z"
          fill="#9A9AAF"
      />
      <path
          d="M162.745 41.7366V50.4728C162.745 51.43 162.995 52.1647 163.496 52.677C163.996 53.1758 164.7 53.4253 165.606 53.4253C166.526 53.4253 167.237 53.1758 167.737 52.677C168.238 52.1647 168.488 51.43 168.488 50.4728V41.7366H171.35V50.4525C171.35 51.6524 171.086 52.6703 170.559 53.5061C170.044 54.3285 169.348 54.9487 168.468 55.3666C167.602 55.7845 166.635 55.9935 165.566 55.9935C164.51 55.9935 163.55 55.7845 162.684 55.3666C161.831 54.9487 161.155 54.3285 160.654 53.5061C160.153 52.6703 159.903 51.6524 159.903 50.4525V41.7366H162.745Z"
          fill="#9A9AAF"
      />
      <path
          d="M178.987 55.9935C177.999 55.9935 177.106 55.825 176.308 55.488C175.523 55.1509 174.901 54.6656 174.441 54.0319C173.98 53.3983 173.744 52.6501 173.73 51.7872H176.775C176.815 52.3669 177.018 52.8253 177.383 53.1624C177.762 53.4994 178.276 53.6679 178.926 53.6679C179.589 53.6679 180.11 53.5129 180.489 53.2028C180.868 52.8792 181.057 52.4613 181.057 51.949C181.057 51.5311 180.929 51.1873 180.671 50.9177C180.414 50.648 180.09 50.4391 179.697 50.2908C179.318 50.129 178.791 49.9537 178.114 49.765C177.194 49.4953 176.443 49.2325 175.861 48.9763C175.293 48.7067 174.799 48.309 174.38 47.7832C173.974 47.2439 173.771 46.5294 173.771 45.6396C173.771 44.8037 173.98 44.0757 174.4 43.4556C174.819 42.8354 175.408 42.3635 176.166 42.04C176.923 41.7029 177.789 41.5344 178.764 41.5344C180.225 41.5344 181.409 41.8917 182.315 42.6062C183.236 43.3073 183.743 44.2914 183.838 45.5587H180.712C180.685 45.0734 180.475 44.6757 180.083 44.3656C179.704 44.042 179.197 43.8802 178.561 43.8802C178.006 43.8802 177.559 44.0218 177.221 44.3049C176.896 44.588 176.734 44.9992 176.734 45.5385C176.734 45.916 176.856 46.2328 177.099 46.4889C177.356 46.7316 177.668 46.9338 178.033 47.0956C178.412 47.2439 178.939 47.4192 179.616 47.6214C180.536 47.891 181.287 48.1607 181.869 48.4303C182.451 48.6999 182.951 49.1044 183.371 49.6436C183.79 50.1829 184 50.8907 184 51.767C184 52.522 183.804 53.223 183.411 53.8701C183.019 54.5173 182.444 55.0363 181.686 55.4273C180.929 55.8048 180.029 55.9935 178.987 55.9935Z"
          fill="#9A9AAF"
      />
      <path
          d="M22.6609 42.0954L17.2007 45.4167L11.9649 38.5221C11.9438 38.4944 11.9165 38.4718 11.8851 38.4563C11.8537 38.4408 11.819 38.4327 11.7839 38.4327C11.7488 38.4327 11.7142 38.4408 11.6827 38.4563C11.6513 38.4718 11.624 38.4944 11.6029 38.5221L6.36997 45.4167L0.906919 42.0954C0.746983 41.9985 0.539348 42.1287 0.564601 42.3143L2.7083 58.3969C2.73916 58.6158 2.92996 58.7847 3.15724 58.7847H20.4162C20.6407 58.7847 20.8343 58.6185 20.8624 58.3969L23.0061 42.3143C23.0285 42.1287 22.8237 41.9985 22.6609 42.0954ZM11.7853 54.7738C10.0429 54.7738 8.6259 53.3749 8.6259 51.6547C8.6259 49.9346 10.0429 48.5357 11.7853 48.5357C13.5278 48.5357 14.9447 49.9346 14.9447 51.6547C14.9447 53.3749 13.5278 54.7738 11.7853 54.7738ZM11.7853 50.3168C11.039 50.3168 10.4329 50.9151 10.4329 51.6547C10.4329 52.3916 11.039 52.9927 11.7853 52.9927C12.5317 52.9927 13.1378 52.3943 13.1378 51.6547C13.1378 50.9179 12.5317 50.3168 11.7853 50.3168Z"
          fill="#5A32FB"
      />
    </svg>
  );
};

export default UpworkPro;
