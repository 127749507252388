import React from "react";
import { StyledArrowHeading, VerticalArrow } from "./styles";
import SliderArrow from "../../assets/icons/SliderArrow";
import Typography from "@mui/material/Typography";
import ContactsArrow from "../../assets/icons/ContactsArrow";
import MediumArrow from "../../assets/icons/MediumArrow";

const ArrowHeading = ({
  smallArrow,
  vertical,
  maxWidth,
  minWidth,
  widthWrapper,
  ...properties
}) => {
  return (
    <StyledArrowHeading
      sx={{
        maxWidth: widthWrapper,
        svg: { maxWidth: maxWidth, minWidth: minWidth },
      }}
    >
      {smallArrow ? (
        <MediumArrow />
      ) : vertical ? (
        <VerticalArrow>
          <ContactsArrow />
        </VerticalArrow>
      ) : (
        <SliderArrow />
      )}
      <Typography variant="h2" {...properties} />
    </StyledArrowHeading>
  );
};

export default ArrowHeading;
