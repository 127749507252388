import React from "react";

const HomeTitleThirdImg = ({ width = 66, height = 69 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 66 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.7253 54.0232L60.6425 28.3667C60.8911 28.2313 61.1106 28.0479 61.2883 27.8273C61.4661 27.6066 61.5986 27.353 61.6783 27.081C61.758 26.809 61.7832 26.5241 61.7525 26.2426C61.7218 25.9611 61.6358 25.6887 61.4994 25.441L56.1851 15.8449C55.9181 15.3726 55.4809 15.0211 54.9629 14.8621C54.4449 14.7031 53.8854 14.7487 53.3987 14.9896L4.82069 39.6914C4.56333 39.8213 4.33482 40.0022 4.14899 40.223C3.96317 40.4439 3.82391 40.7002 3.73964 40.9763C3.65538 41.2525 3.62788 41.5427 3.6588 41.8295C3.68972 42.1162 3.7784 42.3935 3.9195 42.6446L9.90163 53.2147C10.1742 53.6911 10.6214 54.0419 11.1484 54.1928C11.6754 54.3437 12.241 54.2829 12.7253 54.0232Z" fill="white" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.805 35.5349L25.1988 41.5841L23.9671 42.2512L20.9603 39.0351L21.9665 43.3348L20.7463 44.0054L16.4894 39.4973L17.6092 38.8908L20.7399 42.4785L19.5873 37.8193L20.7519 37.1886L23.9525 40.7092L22.6778 36.1454L23.805 35.5349Z" fill="#151515"/>
      <path d="M25.8445 35.5318L26.5589 36.8507L28.3131 35.9006L28.7662 36.7372L27.012 37.6873L27.7672 39.0816L29.7454 38.0102L30.2108 38.8694L27.1875 40.5069L24.334 35.2386L27.3573 33.6011L27.8227 34.4603L25.8445 35.5318Z" fill="#151515"/>
      <path d="M32.5058 34.1365C32.8294 34.0328 33.151 34.0502 33.4708 34.189C33.7905 34.3277 34.0348 34.5529 34.2035 34.8644C34.3559 35.1458 34.4209 35.4322 34.3986 35.7238C34.3786 36.0076 34.2718 36.2799 34.0783 36.5406C33.8849 36.8014 33.6115 37.0274 33.2581 37.2188L31.0112 38.4358L28.1618 33.1751L30.3117 32.0106C30.665 31.8193 30.9999 31.7126 31.3163 31.6907C31.6376 31.6661 31.9188 31.7218 32.1598 31.8577C32.4057 31.9909 32.5995 32.1882 32.741 32.4495C32.907 32.756 32.9637 33.0567 32.911 33.3517C32.8634 33.6439 32.7283 33.9055 32.5058 34.1365ZM30.3826 34.7797L31.3381 34.2621C31.5869 34.1274 31.7485 33.9683 31.823 33.785C31.8948 33.5967 31.8735 33.397 31.7592 33.1859C31.6449 32.9749 31.4893 32.848 31.2923 32.8052C31.0954 32.7624 30.8725 32.8084 30.6237 32.9432L29.6682 33.4607L30.3826 34.7797ZM32.6434 36.4405C32.8973 36.303 33.0612 36.1363 33.1352 35.9402C33.2142 35.7414 33.1938 35.5315 33.0741 35.3104C32.9516 35.0843 32.7817 34.9489 32.5644 34.9041C32.3443 34.8544 32.1074 34.8982 31.8536 35.0357L30.8234 35.5937L31.5909 37.0106L32.6434 36.4405Z" fill="#151515"/>
      <path d="M36.2601 28.7888C36.8075 28.4922 37.3463 28.3402 37.8764 28.3325C38.4114 28.3221 38.8998 28.4573 39.3414 28.7379C39.7854 29.0109 40.1502 29.4112 40.436 29.9387C40.7217 30.4663 40.8576 30.9906 40.8437 31.5115C40.832 32.0247 40.6757 32.5026 40.3747 32.9451C40.0787 33.3848 39.6569 33.753 39.1095 34.0495L37.2881 35.036L34.4387 29.7753L36.2601 28.7888ZM38.5864 33.1728C39.1338 32.8763 39.4752 32.4965 39.6105 32.0333C39.7458 31.5701 39.6651 31.0646 39.3685 30.5169C39.0718 29.9692 38.6912 29.623 38.2266 29.4783C37.7593 29.3285 37.2519 29.4018 36.7045 29.6983L35.9655 30.0986L37.8474 33.5731L38.5864 33.1728Z" fill="#151515"/>
      <path d="M41.1465 27.2436L41.8609 28.5625L43.6152 27.6124L44.0683 28.449L42.3141 29.3991L43.0693 30.7935L45.0475 29.722L45.5129 30.5812L42.4896 32.2187L39.6361 26.9504L42.6594 25.3129L43.1247 26.1721L41.1465 27.2436Z" fill="#151515"/>
      <path d="M48.1443 22.3518L49.0827 28.6476L47.8137 29.335L43.0533 25.1093L44.173 24.5028L47.8719 27.9095L47.0321 22.9543L48.1443 22.3518Z" fill="#151515"/>

    </svg>
  );
};

export default HomeTitleThirdImg;
