import React from "react";

const Clutch = ({ width = 208, height = 93 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 208 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M54.3149 0.909302H63.8411V59.7233H54.3149V0.909302Z"
          fill="#17313B"
      />
      <path
          d="M96.9757 41.085C96.9757 50.197 89.5204 51.0254 87.0353 51.0254C81.2367 51.0254 80.4083 45.641 80.4083 42.7417V19.9617H70.8821V42.3275C70.8821 47.7119 72.5389 52.6821 75.8523 55.5814C78.7516 58.4807 82.4793 59.7232 87.0353 59.7232C90.3487 59.7232 94.4906 58.8949 96.9757 56.4098V59.7232H106.502V19.9617H96.9757V41.085Z"
          fill="#17313B"
      />
      <path
          d="M125.14 5.46533H115.614V19.9617H108.573V29.0738H115.614V59.7233H125.14V29.0738H132.181V19.9617H125.14V5.46533Z"
          fill="#17313B"
      />
      <path
          d="M162.002 47.712C159.931 49.3688 157.032 50.6113 154.133 50.6113C147.506 50.6113 142.95 45.6411 142.95 39.0142C142.95 32.3873 147.506 27.8313 154.133 27.8313C157.032 27.8313 159.931 28.6596 162.002 30.7305L163.245 31.9731L169.872 25.7603L168.215 24.5178C164.487 21.2043 159.517 19.1334 154.133 19.1334C142.536 19.1334 133.838 27.8313 133.838 39.4284C133.838 51.0255 142.536 59.7233 154.133 59.7233C159.517 59.7233 164.487 57.6524 168.215 54.339L169.872 53.0964L163.245 46.4695L162.002 47.712Z"
          fill="#17313B"
      />
      <path
          d="M203.421 23.2752C200.521 20.3759 197.622 19.1334 193.066 19.1334C189.753 19.1334 186.439 19.9617 183.954 22.4468V0.909302H174.428V59.7233H183.954V37.7716C183.954 28.6596 190.167 27.8312 192.652 27.8312C198.45 27.8312 198.036 33.2156 198.036 36.1148V59.3091H207.563V36.529C207.563 31.1447 206.32 26.1745 203.421 23.2752Z"
          fill="#17313B"
      />
      <path
          d="M153.719 46.0553C157.379 46.0553 160.346 43.0883 160.346 39.4283C160.346 35.7684 157.379 32.8014 153.719 32.8014C150.059 32.8014 147.092 35.7684 147.092 39.4283C147.092 43.0883 150.059 46.0553 153.719 46.0553Z"
          fill="#EF4335"
      />
      <path
          d="M41.0609 45.2268C37.7475 48.5403 32.7773 50.6112 27.3929 50.6112C17.0383 50.6112 9.58303 42.3275 9.58303 31.1446C9.58303 19.9617 17.0383 11.678 27.8071 11.678C32.7773 11.678 37.7475 13.7489 41.4751 17.4766L42.7177 18.7191L48.9304 12.5064L47.6879 11.2638C42.3035 5.87943 35.2624 2.98015 27.8071 2.98015C12.0681 2.56597 0.471008 14.9915 0.471008 31.1446C0.471008 47.2977 12.0681 59.7232 27.3929 59.7232C34.8482 59.7232 42.3035 56.8239 47.2737 51.4395L48.5162 50.197L42.3035 43.9843L41.0609 45.2268Z"
          fill="#17313B"
      />
      <path
          d="M13.1051 74.1929H4.283V78.7529C4.6642 78.2907 5.20878 77.9173 5.91672 77.6329C6.62466 77.3307 7.37798 77.1796 8.17669 77.1796C9.62888 77.1796 10.8179 77.4907 11.7436 78.1129C12.6694 78.7351 13.341 79.5351 13.7585 80.5129C14.176 81.4729 14.3848 82.504 14.3848 83.6062C14.3848 85.6507 13.7858 87.2951 12.5877 88.5396C11.4078 89.784 9.71964 90.4062 7.5232 90.4062C5.45383 90.4062 3.80197 89.8996 2.56761 88.8862C1.33325 87.8729 0.63438 86.5485 0.471008 84.9129H4.17409C4.33746 85.624 4.70051 86.1929 5.26323 86.6196C5.84411 87.0462 6.57928 87.2596 7.46875 87.2596C8.53973 87.2596 9.34751 86.9307 9.89209 86.2729C10.4367 85.6151 10.7089 84.744 10.7089 83.6596C10.7089 82.5573 10.4276 81.7218 9.86486 81.1529C9.32029 80.5662 8.51251 80.2729 7.44152 80.2729C6.67912 80.2729 6.04378 80.4596 5.53552 80.8329C5.02725 81.2062 4.6642 81.704 4.44638 82.3262H0.797751V70.9129H13.1051V74.1929Z"
          fill="#151515"
      />
      <path
          d="M19.6142 90.5929C18.9244 90.5929 18.3526 90.3885 17.8988 89.9796C17.4631 89.5529 17.2453 89.0285 17.2453 88.4062C17.2453 87.784 17.4631 87.2685 17.8988 86.8596C18.3526 86.4329 18.9244 86.2196 19.6142 86.2196C20.2858 86.2196 20.8394 86.4329 21.2751 86.8596C21.7108 87.2685 21.9286 87.784 21.9286 88.4062C21.9286 89.0285 21.7108 89.5529 21.2751 89.9796C20.8394 90.3885 20.2858 90.5929 19.6142 90.5929Z"
          fill="#151515"
      />
      <path
          d="M24.7676 80.4329C24.7676 77.3573 25.3303 74.9485 26.4558 73.2062C27.5994 71.464 29.4872 70.5929 32.1193 70.5929C34.7514 70.5929 36.6302 71.464 37.7556 73.2062C38.8992 74.9485 39.471 77.3573 39.471 80.4329C39.471 83.5262 38.8992 85.9529 37.7556 87.7129C36.6302 89.4729 34.7514 90.3529 32.1193 90.3529C29.4872 90.3529 27.5994 89.4729 26.4558 87.7129C25.3303 85.9529 24.7676 83.5262 24.7676 80.4329ZM35.7135 80.4329C35.7135 79.1173 35.6227 78.0151 35.4412 77.1262C35.2778 76.2196 34.9329 75.4818 34.4065 74.9129C33.8982 74.344 33.1358 74.0596 32.1193 74.0596C31.1028 74.0596 30.3313 74.344 29.8049 74.9129C29.2966 75.4818 28.9517 76.2196 28.7702 77.1262C28.6068 78.0151 28.5251 79.1173 28.5251 80.4329C28.5251 81.784 28.6068 82.9218 28.7702 83.8462C28.9336 84.7529 29.2785 85.4907 29.8049 86.0596C30.3313 86.6107 31.1028 86.8862 32.1193 86.8862C33.1358 86.8862 33.9073 86.6107 34.4337 86.0596C34.9601 85.4907 35.305 84.7529 35.4684 83.8462C35.6318 82.9218 35.7135 81.784 35.7135 80.4329Z"
          fill="#151515"
      />
      <path
          d="M61.3407 68.7233L65.3754 76.007L73.2104 77.7909L67.869 84.0764L68.6766 92.4626L61.3407 89.0636L54.0048 92.4626L54.8124 84.0764L49.471 77.7909L57.306 76.007L61.3407 68.7233Z"
          fill="#EF4335"
      />
      <path
          d="M94.5758 68.7233L98.6105 76.007L106.445 77.7909L101.104 84.0764L101.912 92.4626L94.5758 89.0636L87.2399 92.4626L88.0475 84.0764L82.7061 77.7909L90.5411 76.007L94.5758 68.7233Z"
          fill="#EF4335"
      />
      <path
          d="M127.811 68.7233L131.846 76.007L139.681 77.7909L134.339 84.0764L135.147 92.4626L127.811 89.0636L120.475 92.4626L121.283 84.0764L115.941 77.7909L123.776 76.007L127.811 68.7233Z"
          fill="#EF4335"
      />
      <path
          d="M161.046 68.7233L165.081 76.007L172.916 77.7909L167.574 84.0764L168.382 92.4626L161.046 89.0636L153.71 92.4626L154.518 84.0764L149.176 77.7909L157.011 76.007L161.046 68.7233Z"
          fill="#EF4335"
      />
      <path
          d="M194.281 68.7233L198.316 76.007L206.151 77.7909L200.81 84.0764L201.617 92.4626L194.281 89.0636L186.945 92.4626L187.753 84.0764L182.411 77.7909L190.246 76.007L194.281 68.7233Z"
          fill="#EF4335"
      />
    </svg>
  );
};

export default Clutch;
