import React from "react";
import palette from "../../theme/palette";

const StarIcon = ({ width = 25, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M12.6515 0.186035L16.6863 7.46973L24.5212 9.25367L19.1799 15.5392L19.9874 23.9254L12.6515 20.5264L5.31568 23.9254L6.12322 15.5392L0.78186 9.25367L8.61682 7.46973L12.6515 0.186035Z"
          fill={palette.carminePink}
      />
    </svg>
  );
};

export default StarIcon;
