/* eslint-disable array-callback-return */
import React from "react";
import { graphql, Link } from "gatsby";
import Typography from "@mui/material/Typography";
import Layout from "../layout";
import Seo from "../components/seo";
import {
  CasesSubTitle,
  ReviewsContainerBackgroundInner,
  SArrowNext,
  SArrowPrev,
  SBtnContainer,
  SBtnWrapper,
  SCasesContainer,
  SCasesContent,
  SCustomerAddress,
  SCustomerCompany,
  SCustomerContainer,
  SCustomerInfo,
  SCustomerLeft,
  SCustomerName,
  SCustomerText,
  SCustomerTitle,
  ServicesContainer,
  SHomeTopAnimation,
  SImgContainer,
  SLinkCases,
  SPreviewImg,
  SRating,
  SRatingIcon,
  SReviewsContainer,
  SReviewsTitle,
  SServicesWrapper,
  SSpecializationContainer,
  STechnologiesPreview,
  STitleBlock,
  SUpworkArrow,
  SUpworkContainer,
  SUpworkItem,
  SUpworkTitle,
  SUpworkWrapper,
  TitleFirstIcon,
  TitleSecondIcon,
  TitleThirdIcon,
} from "../styles/home";
import Upwork from "../assets/icons/Upwork";
import UpworkPro from "../assets/icons/UpworkPro";
import ArrowRight from "../assets/icons/ArrowRight";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SliderArrow from "../assets/icons/SliderArrow";
import CasesPreview from "../components/casesPreview";
import homeAnimation from "../assets/animations/homeTopAnimation.json";
import { HOST_IMAGE_URL } from "../constants";
import palette from "../theme/palette";
import Clutch from "../assets/icons/Clutch";
import Container from "../components/container";
import SliderSmallArrow from "../assets/icons/SliderSmallArrow";
import Address from "../assets/icons/Address";
import StarIcon from "../assets/icons/StarIcon";
import StarBorderIcon from "../assets/icons/StarBorderIcon";
import {
  SliderAdaptiveContainer,
  SliderBackground,
} from "../components/slider/styles";
import ArrowHeading from "../components/arrowHeading";
import CustomerReviewsBg from "../assets/icons/CustomerReviewsBg";
import TechnologyImg from "../assets/icons/TechnologyImg";
import HomeTitleFirstImg from "../assets/icons/HomeTitleFirstImg";
import HomeTitleSecondImg from "../assets/icons/HomeTitleSecondImg";
import HomeTitleThirdImg from "../assets/icons/HomeTitleThirdImg";
import LottieIcon from "../components/lottieIcons";
import Services from "../components/services/Services";
import ServicesSlider from "../components/services/ServicesSlider";
import MainTarget from "../components/mainTarget";

function IndexPage({
  data: {
    strapi: {
      homepage,
      technologiesLists,
      mainTargets,
      categories,
      cases,
      reviews,
    },
  },
}) {
  const renderArrowPrev = (clickHandler) => {
    return (
      <SArrowPrev onClick={clickHandler}>
        <SliderSmallArrow />
      </SArrowPrev>
    );
  };

  const renderArrowNext = (clickHandler) => {
    return (
      <SArrowNext onClick={clickHandler}>
        <SliderSmallArrow />
      </SArrowNext>
    );
  };

  // SETTINGS ANIMATION IMG
  const topAnimation = {
    animationData: homeAnimation,
    loop: 0,
    autoplay: true,
  };
  // END OF SETTINGS ANIMATION IMG

  return (
    <Layout>
      <Seo
        title={homepage?.data?.attributes?.seo?.metaTitle}
        description={homepage?.data?.attributes?.seo?.metaDescription}
        image={
          homepage?.data?.attributes?.seo?.metaImage?.data?.attributes?.url
        }
      />
      <Container direction="column">
        <SSpecializationContainer>
          <STitleBlock>
            <TitleFirstIcon>
              <HomeTitleFirstImg />
            </TitleFirstIcon>
            <TitleSecondIcon>
              <HomeTitleSecondImg />
            </TitleSecondIcon>
            <TitleThirdIcon>
              <HomeTitleThirdImg />
            </TitleThirdIcon>
            <Typography
              variant="h1"
              dangerouslySetInnerHTML={{
                __html: homepage.data.attributes.title,
              }}
            />
            <Typography variant="body1" component="h2">
              {homepage.data.attributes.description}
            </Typography>
          </STitleBlock>
          <SImgContainer>
            <SHomeTopAnimation>
              <LottieIcon options={topAnimation} replayInView disableOnHover />
            </SHomeTopAnimation>
          </SImgContainer>
        </SSpecializationContainer>
        <STechnologiesPreview>
          <SPreviewImg>
            {technologiesLists?.data?.map((item) => {
              if (item.attributes.favourite === true) {
                return (
                  <div key={item.id}>
                    <img
                      src={`${
                        HOST_IMAGE_URL +
                        item?.attributes?.icon?.data?.attributes?.url
                      }`}
                      alt={
                        item?.attributes?.icon?.data?.attributes
                          ?.alternativeText
                      }
                      title={item?.attributes?.icon?.data?.attributes?.caption}
                    />
                  </div>
                );
              }
            })}
          </SPreviewImg>
          <SImgContainer>
            <TechnologyImg />
          </SImgContainer>
        </STechnologiesPreview>

        <SServicesWrapper>
          <ArrowHeading>{homepage.data.attributes.whatWeDoTitle}</ArrowHeading>
          {/* SERVICES SECTION */}
          <ServicesContainer>
            <SliderAdaptiveContainer>
              <ServicesSlider categories={categories} />
              <SliderBackground />
            </SliderAdaptiveContainer>
            <Services
              categories={categories}
              serviceItem="desktopServiceItem"
            />
            <MainTarget mainTargets={mainTargets} />
          </ServicesContainer>
          {/* END OF SERVICES SECTION */}
        </SServicesWrapper>

        {/* UPWORK SECTION */}
        <SUpworkContainer>
          <SUpworkTitle variant="h2">
            {homepage.data.attributes.whyWe.title}
          </SUpworkTitle>
          <SUpworkArrow>
            <SliderArrow />
          </SUpworkArrow>
          <SUpworkWrapper>
            <SUpworkItem>
              <a
                href="https://www.upwork.com/ag/malevich"
                target="_blank"
                rel="noreferrer"
              >
                <Upwork />
              </a>
              <a
                href="https://www.upwork.com/ag/malevich"
                target="_blank"
                rel="noreferrer"
              >
                <UpworkPro />
              </a>
            </SUpworkItem>
            <SUpworkItem>
              <a
                href="https://clutch.co/profile/malevich#summary"
                target="_blank"
                rel="noreferrer"
              >
                <Clutch />
              </a>
            </SUpworkItem>
          </SUpworkWrapper>
        </SUpworkContainer>
        {/* END OF UPWORK SECTION */}

        {/* CASES SECTION */}
        <SCasesContainer>
          <ArrowHeading>{homepage.data.attributes.cases.title}</ArrowHeading>
          <CasesSubTitle variant="body2">
            {homepage.data.attributes.cases.description}
          </CasesSubTitle>
          <Link to="/#footer">
            <SBtnContainer
              className="btnOutline"
              type="button"
              endIcon={<ArrowRight />}
            >
              Let's create something together
            </SBtnContainer>
          </Link>
          <SCasesContent>
            {cases?.data?.map((item) => {
              if (item?.attributes?.isShowOnHomePage) {
                return <CasesPreview key={item.id} data={item} />;
              }
            })}
          </SCasesContent>
          <SBtnWrapper>
            <SLinkCases to="/cases">
              <SBtnContainer
                type="button"
                endIcon={<ArrowRight color={palette.secondary} />}
              >
                See all works
              </SBtnContainer>
            </SLinkCases>
          </SBtnWrapper>
        </SCasesContainer>
        {/* END OF CASES SECTION */}

        {/* CUSTOMER REVIEWS SECTION */}
        <SReviewsContainer>
          <ReviewsContainerBackgroundInner>
            <CustomerReviewsBg />
          </ReviewsContainerBackgroundInner>
          <SReviewsTitle variant="h2">
            {homepage.data.attributes.reviewsTitle}
          </SReviewsTitle>
          <Carousel
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop
            renderArrowPrev={renderArrowPrev}
            renderArrowNext={renderArrowNext}
          >
            {reviews.data.map((review) => {
              return (
                <SCustomerContainer key={review.id}>
                  <SCustomerInfo>
                    <SCustomerLeft>
                      <SCustomerTitle>
                        <SCustomerName variant="body1">
                          {review.attributes.name}
                        </SCustomerName>
                        <SCustomerCompany variant="body2">
                          {review.attributes.company}
                        </SCustomerCompany>
                      </SCustomerTitle>
                      {review.attributes.address && (
                        <SCustomerAddress variant="subtitle1" component="p">
                          <Address />
                          {review.attributes.address}
                        </SCustomerAddress>
                      )}
                    </SCustomerLeft>
                    <SRating
                      name="customized"
                      defaultValue={review.attributes.rating}
                      icon={
                        <SRatingIcon>
                          <StarIcon />
                        </SRatingIcon>
                      }
                      emptyIcon={
                        <SRatingIcon>
                          <StarBorderIcon />
                        </SRatingIcon>
                      }
                      readOnly
                    />
                  </SCustomerInfo>
                  <SCustomerText variant="body2">
                    {review.attributes.text}
                  </SCustomerText>
                </SCustomerContainer>
              );
            })}
          </Carousel>
        </SReviewsContainer>
        {/* END OF CUSTOMER REVIEWS SECTION */}
      </Container>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    strapi {
      technologiesLists(pagination: { limit: 100 }) {
        data {
          id
          attributes {
            favourite
            title
            icon {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
          }
        }
      }

      mainTargets {
        data {
          id
          attributes {
            quantity
            description
          }
        }
      }

      homepage {
        data {
          attributes {
            seo {
              ...seo
            }
            title
            description
            whatWeDoTitle
            whyWe {
              title
            }
            cases {
              title
              description
            }
            reviewsTitle

            localizations {
              data {
                attributes {
                  title
                  description
                  whatWeDoTitle
                  whyWe {
                    title
                  }
                  cases {
                    title
                    description
                  }
                  reviewsTitle
                }
              }
            }
          }
        }
      }

      advantages {
        data {
          id
          attributes {
            title
            description
          }
        }
      }

      categories {
        data {
          id
          attributes {
            seo {
              ...seo
            }
            slug

            preview {
              title
              description
              icon {
                data {
                  attributes {
                    url
                    alternativeText
                    caption
                  }
                }
              }
              
              hoverIcon {
                data {
                  attributes {
                    url
                    alternativeText
                    caption
                  }
                }
              }
            }
          }
        }
      }

      cases(sort: ["sort:desc","createdAt:desc"]) {
        data {
          id
          attributes {
            slug
            title
            description
            isShowOnHomePage
            mainImage {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            mainCategory {
              data {
                attributes {
                  preview {
                    title
                  }
                }
              }
            }
          }
        }
      }

      reviews {
        data {
          id
          attributes {
            name
            company
            address
            rating
            text
          }
        }
      }
    }
  }
`;
