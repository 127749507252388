import React from "react";
import palette from "../../theme/palette";

const SliderArrow = ({ width = 132, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 132 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M1 3.68604C0.723858 3.68604 0.5 3.90989 0.5 4.18604C0.5 4.46218 0.723858 4.68604 1 4.68604L1 3.68604ZM131.354 4.53958C131.549 4.34431 131.549 4.02773 131.354 3.83247L128.172 0.65049C127.976 0.455228 127.66 0.455228 127.464 0.65049C127.269 0.845752 127.269 1.16233 127.464 1.3576L130.293 4.18602L127.464 7.01445C127.269 7.20971 127.269 7.5263 127.464 7.72156C127.66 7.91682 127.976 7.91682 128.172 7.72156L131.354 4.53958ZM1 4.68604L131 4.68602L131 3.68602L1 3.68604L1 4.68604Z"
          fill={palette.primary}
      />
    </svg>
  );
};

export default SliderArrow;
