import React from "react";
import palette from "../../theme/palette";

const MediumArrow = ({ width = 56, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM55.3535 4.35356C55.5488 4.1583 55.5488 3.84171 55.3535 3.64645L52.1716 0.464471C51.9763 0.269208 51.6597 0.269208 51.4645 0.464471C51.2692 0.659733 51.2692 0.976315 51.4645 1.17158L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34028 51.4645 7.53554C51.6597 7.7308 51.9763 7.7308 52.1716 7.53554L55.3535 4.35356ZM1 4.5L55 4.5L55 3.5L1 3.5L1 4.5Z"
          fill={palette.primary}
      />
    </svg>
  );
};

export default MediumArrow;
