import React from "react";

const CustomerReviewsBg = ({ color = "#151515", width = 277, height = 173 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 277 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M100.727 172.785H0L73.5037 0.785156H139.521L100.727 172.785ZM238.206 172.785H137.479L210.983 0.785156H277L238.206 172.785Z"
            fill={color}
            fillOpacity="0.1"
      />
    </svg>
  );
};

export default CustomerReviewsBg;
