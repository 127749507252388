import React from "react";
import palette from "../../theme/palette";

const StarBorderIcon = ({ width = 25, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M12.1728 2.24975L15.3328 7.95429L15.5495 8.34549L15.9855 8.44477L22.2127 9.86264L17.9391 14.8916L17.6652 15.214L17.7057 15.635L18.346 22.2846L12.5932 19.619L12.1728 19.4243L11.7524 19.619L5.99955 22.2846L6.63986 15.635L6.6804 15.214L6.40648 14.8916L2.1329 9.86264L8.36007 8.44477L8.79612 8.34549L9.01282 7.95429L12.1728 2.24975Z"
          stroke={palette.carminePink}
          strokeWidth="2"
      />
    </svg>
  );
};

export default StarBorderIcon;
